
import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/rootReducer";
import { configThunk } from "../../../../../redux/action/config/config.thunk";
import { PurchaseOrder, Supplier, EOProduct, PurchaseOrderRequest,  PurchaseOrders, InputDeliveryRequest, EopRequest} from "../../../../../models/config.model";
import { configService } from "../../../../../Services/configService";
import { useAlert } from "react-alert";
import { useLoadingMessage } from "../../../../../components/useLoadingMessage";
import Select from 'react-select';


interface PurchaseOrderOption {
  value: string;
  label: string;
}

interface EopWithReceived extends EOProduct {
  quantityReceived: number;
  remainingQuantity: number;

}

interface InputDeliveryFormProps {
  open: boolean;
  toggle: () => void;
  refreshData: () => void;
  supplierId?: number;
  stateId: number;
  purchaseOrderId: string;
}


export interface PurchaseOrderResponse {
  message: string;
  errors: string | null;
  data: PurchaseOrders;
}

const InputDeliveries: React.FC<InputDeliveryFormProps> = ({
  open,
  toggle,
  refreshData,
  supplierId,
  stateId,
 
  purchaseOrderId,
}) => {

  const [selectedSupplier, setSelectedSupplier] = useState<number | null>(supplierId || null);
  const [deliveryImage, setDeliveryImage] = useState<File | null>(null);
  const [eoPs, setEoPs] = useState<EopWithReceived[]>([]);
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [purchaseOrderData, setPurchaseOrderData] = useState<PurchaseOrder[]>([]);
  const [purchaseOrderOptions, setPurchaseOrderOptions] = useState<PurchaseOrderOption[]>([]);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState<PurchaseOrderOption | null>(null);
  const [managerId, setManagerId] = useState<string | null>(null);
  const [selectedWarehouseUniqueId, setSelectedWarehouseUniqueId] = useState<string>("");
  const [selectedSupplierId, setSelectedSupplierId] = useState<number | null>(null);
    
 
  const { suppliers } = useSelector((state: RootState) => state.suppliers);
  
  const dispatch = useDispatch();
  const alert = useAlert();

  const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setDeliveryImage(file);
    }
  };

  console.log("StateId", stateId)

   const getPurchaseOrdersByState = async (stateId: number) => {
    updateLoader(true, "Fetching Purchase Orders");
    try {
      const response = await configService.getPurcahseOrderByState(stateId);
      const orders = response?.data?.response || []; 
      console.log("PO ByState", orders);
      setPurchaseOrderData(orders);
      const options = orders.map((order: any) => ({
        value: order.id.toString(),
        label: `Order Number ${order.id} Supplier Name: ${order.supplierName} ${new Date(order.createdAt).toLocaleDateString()}`
      }));
      setPurchaseOrderOptions(options);
    } catch (error) {
      alert.error("Error fetching purchase orders");
    } finally {
      updateLoader(false, "");
    }
  };


  const loadPurchaseOrder = async (orderId: number) => {
    updateLoader(true, "Loading Purchase Order Details");
    try {
      const response: { data: PurchaseOrderResponse } = await configService.getOnePurchaseOrder(orderId);
      const poData = response.data.data;

      console.log("Data666", poData)
      
    
      setSelectedWarehouseUniqueId(poData.warehouseUniqueId);
      console.log('Warehouse ID in loadPurchaseOrder:', poData.warehouseUniqueId);
      setSelectedSupplierId(poData.supplierId); 
      setManagerId(poData.managerId);
      
        
      const formattedEoPs = poData.purchaseOrderEOPs.map((eop: any) => ({
        id: eop.eopId,
        name: eop.eopName,
        quantity: eop.quantityRequested,
        maxQuantity: eop.quantityRequested,
        quantityReceived: 0,
        remainingQuantity: eop.quantityRequested,
      }));
      
      setEoPs(formattedEoPs);
    } catch (error) {
      alert.error("Error loading purchase order details");
    } finally {
      updateLoader(false, "");
    }
  };

  const handlePurchaseOrderChange = (option: PurchaseOrderOption | null) => {
    setSelectedPurchaseOrder(option);
    if (option) {
      loadPurchaseOrder(parseInt(option.value));
    }
  };

  const handleQuantityReceivedChange = (
    index: number,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const updatedQuantityReceived = parseInt(e.target.value) || 0;
    
    setEoPs((prevEoPs) => {
      return prevEoPs.map((eoProduct, i) => {
        if (i === index) {
          const originalQuantity = eoProduct.quantity;
          const validQuantityReceived = Math.min(updatedQuantityReceived, originalQuantity);
          const newRemainingQuantity = Math.max(0, originalQuantity - validQuantityReceived);
          
          return {
            ...eoProduct,
            quantityReceived: validQuantityReceived,
            remainingQuantity: newRemainingQuantity,
          };
        }
        return eoProduct;
      });
    });
  };

  const getroles = React.useCallback(async () => {
    await dispatch(configThunk.getAllSuppliers(suppliers));
  }, [suppliers]);
   
useEffect(() => {
    if (stateId) {
      getPurchaseOrdersByState(stateId);
    }
    getroles();
    if (purchaseOrderId) {
      loadPurchaseOrder(parseInt(purchaseOrderId));
    }
  }, [stateId, purchaseOrderId]);



const handleSubmit = async (e: React.FormEvent) => {
  e.preventDefault();

  if (!selectedPurchaseOrder) {
    alert.error("Please select a purchase order");
    return;
  }

  
  const inputDeliveryRequest: InputDeliveryRequest = {
    warehouseUniqueId: selectedWarehouseUniqueId,
    managerId: managerId ?? undefined,
    stateId: stateId,
    purchaseOrderId: parseInt(selectedPurchaseOrder.value),
    isAccepted: true,
    eopRequests: eoPs
      .filter(eop => eop.quantityReceived > 0)
      .map(({ id, quantityReceived }) => ({
        eopId: Number(id),       
        quantity: Number(quantityReceived) 
      }))
  };
  
  if (deliveryImage) {
  
    inputDeliveryRequest.attachedFile = deliveryImage.name;
  }

  try {
    
    const response = await configService.inputDelivery(inputDeliveryRequest);
    alert.success(response.data.message);
    refreshData();
    toggle();
  } catch (error: any) {
    if (error.response?.data?.errors) {
      const errorMessages = Object.entries(error.response.data.errors)
        .map(([field, messages]) => {
          if (Array.isArray(messages)) {
            return messages.map(msg => `${field}: ${msg}`);
          }
          return [`${field}: ${messages}`];
        })
        .flat();

      errorMessages.forEach((message: string) => {
        alert.error(message);
      });
    } else {
      const errorMessage = error.response?.data?.message || 'An error occurred';
      alert.error(errorMessage);
    }
  }
};

  return (
    <Modal isOpen={open} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Input Delivery</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label for="purchaseOrder">Select Purchase Order</Label>
                <Select
                  id="purchaseOrder"
                  value={selectedPurchaseOrder}
                  onChange={handlePurchaseOrderChange}
                  options={purchaseOrderOptions}
                  isSearchable
                  placeholder="Select a purchase order..."
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={12}>
              <FormGroup>
                <Label for="supplier">Supplier</Label>
                <Select
                  name="supplier"
                  id="supplier"
                  value={suppliers
                    .map((supplier: Supplier) => ({
                      label: supplier.name,
                      value: supplier.id,
                    }))
                    .find((option) => option.value === selectedSupplierId)}
                  onChange={(option) => setSelectedSupplier(option?.value || null)}
                  options={suppliers.map((supplier: Supplier) => ({
                    label: supplier.name,
                    value: supplier.id,
                  }))}
                  isDisabled={true}
                  
                />
              </FormGroup>
            </Col>
          </Row>
          {eoPs.map((eoProduct, index) => (
            <Row key={index} className="mb-3 align-items-end">
              <Col md={3}>
                <FormGroup>
                  <Label>Product Name</Label>
                  <Input type="text" value={eoProduct.name} disabled />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Expected Quantity</Label>
                  <Input 
                    type="text" 
                    value={eoProduct.quantity} 
                    disabled 
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Received Quantity</Label>
                  <Input
                    type="number"
                    value={eoProduct.quantityReceived}
                    onChange={(e) => handleQuantityReceivedChange(index, e)}
                    min={0}
                    max={eoProduct.quantity}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Remaining Quantity</Label>
                  <Input 
                    type="text" 
                    value={eoProduct.remainingQuantity} 
                    disabled 
                  />
                </FormGroup>
              </Col>
            </Row>
          ))}
          
          <FormGroup className="mt-4">
            <Label for="deliveryImage">Upload Delivery Image</Label>
            <Input
              type="file"
              id="deliveryImage"
              name="deliveryImage"
              accept="image/*"
              onChange={handleImageUpload}
              style={{
                width: '100%',
                padding: '0.375rem 0.75rem',
                border: '1px solid #ced4da',
                borderRadius: '0.25rem',
                lineHeight: '1.5',
                backgroundColor: '#fff',
              }}
            />
          </FormGroup>
          <Button type="submit" color="success" className="w-100 mt-3">
            Save Delivery
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default InputDeliveries;