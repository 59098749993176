import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
} from "reactstrap";
import { FaTimesCircle } from "react-icons/fa";
import { seasonService } from "../../../../../Services";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/rootReducer";
import { configThunk } from "../../../../../redux/action/config/config.thunk";
import {
  EOProduct,
  Inventory,
  WarehouseInventoryItem,
  InputDistributionRequest
} from "../../../../../models/config.model";
import { configService } from "../../../../../Services/configService";
import { useLoadingMessage } from "../../../../../components/useLoadingMessage";
import { useAlert } from "react-alert";
import Select from "react-select";

interface EopWithReceived extends EOProduct {
  quantityReceived: number;
  remainingQuantity: number;
}


interface Warehouse {
  value: string;
  label: string;
  managerId?: string | null;
}


interface InputDisributionFormProps {
  openModal: boolean;
  toggle: () => void;
  refreshData: () => void;
  supplierId?: number;
  stateId?: number;
  clusters: any[];
  warehouses: Warehouse[];
}

const InputDistribution: React.FC<InputDisributionFormProps> = ({
  openModal,
  toggle,
  clusters,
  warehouses,
  refreshData,
  stateId,
}) => {
  const [eoPs, setEoPs] = useState<EopWithReceived[]>([]);
  const [currentEOProduct, setCurrentEOProduct] = useState<EopWithReceived>({
    id: 0,
    name: "",
    quantity: 0,
    maxQuantity: 0,
    quantityReceived: 0,
    remainingQuantity: 0,
  });

  const [selectedWarehouse, setSelectedWarehouse] = useState<any>(null);
  
  const [selectedCluster, setSelectedCluster] = useState<any>(null);
  const [deliveryImage, setDeliveryImage] = useState<File | null>(null);
  const [warehouseInventories, setWarehouseInevtories] = useState<
    WarehouseInventoryItem[]
  >([]);
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const { suppliers } = useSelector((state: RootState) => state.suppliers);
  const dispatch = useDispatch();
  const alert = useAlert();

  useEffect(() => {
    dispatch(configThunk.getAllSuppliers(suppliers));
  }, []);


    useEffect(() => {
    if (selectedWarehouse) {
      console.log(
        "This is the warehouseId passed to fetchWarehouseInventories ",
        selectedWarehouse.value
      );
      fetchWarehouseInventories(selectedWarehouse.value);
    }
  }, [selectedWarehouse]);


  const fetchWarehouseInventories = async (warehouseUniqueId: string) => {
  updateLoader(true, "Fetching Inventories");
  try {
    const response = await configService.getInventoriesByWarehouse(warehouseUniqueId);
    console.log("Warehouse Inventories Response:", response);
    if (response && response.data && response.data.data) {
      setWarehouseInevtories(response.data.data); 
    } else {
      console.error("Unexpected response structure:", response);
      setWarehouseInevtories([]);
    }
  } catch (error) {
    console.error("Error fetching warehouse inventories:", error);
    alert.error("Failed to fetch warehouse inventories");
    setWarehouseInevtories([]);
  } finally {
    updateLoader(false, "");
  }
};


  const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setDeliveryImage(e.target.files[0]); // Store the selected image file
    }
  };

  const addEOProduct = () => {
    if (currentEOProduct.id > 0 && currentEOProduct.quantityReceived > 0) {
      if (currentEOProduct.quantityReceived > currentEOProduct.maxQuantity) {
        alert.error("Quantity exceeds the maximum allowed.");
        return;
      }
      if (eoPs.some((product) => product.id === currentEOProduct.id)) {
        alert.info("Product already added.");
        return;
      }
      setEoPs((prevEoPs) => [...prevEoPs, currentEOProduct]);
      setCurrentEOProduct({
        id: 0,
        name: "",
        quantity: 0,
        maxQuantity: 0,
        quantityReceived: 0,
        remainingQuantity: 0,
      });
    } else {
      alert.info("Please select a valid product and enter a valid quantity.");
    }
  };

  const removeEOProduct = (index: number) => {
    setEoPs((prevEoPs) => prevEoPs.filter((_, i) => i !== index));
  };

  const handleWarehouseChange = (selectedOption: any) => {
    console.log("Warehouse Changed:", selectedOption);
    setSelectedWarehouse(selectedOption);
    setCurrentEOProduct({
      id: 0,
      name: "",
      quantity: 0,
      maxQuantity: 0,
      quantityReceived: 0,
      remainingQuantity: 0,
    });
    setEoPs([]);
  };

  const handleProductChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedEopId = parseInt(e.target.value);
    const selectedInventory = warehouseInventories.find(
      (item) => item.eop.eopId === selectedEopId
    );
    console.log("Selected Product:", selectedInventory);
    if (selectedInventory) {
      setCurrentEOProduct({
        id: selectedInventory.eop.eopId,
        name: selectedInventory.eop.eopName,
        quantity: selectedInventory.totalQuantity,
        maxQuantity:
          selectedInventory.totalQuantity -
          selectedInventory.distributedQuantity,
        quantityReceived: 0,
        remainingQuantity:
          selectedInventory.totalQuantity -
          selectedInventory.distributedQuantity,
      });
    }
  };
  const handleQuantityChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputQuantity = parseInt(e.target.value) || 0;
    if (inputQuantity <= currentEOProduct.maxQuantity) {
      setCurrentEOProduct((prev) => ({
        ...prev,
        quantityReceived: inputQuantity,
        remainingQuantity: prev.maxQuantity - inputQuantity,
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!selectedWarehouse || !selectedCluster) {
      alert.error("Please select both a warehouse and a cluster.");
      return;
    }

    const inputDistributionRequestBody: InputDistributionRequest = {
      warehouseUniqueId: selectedWarehouse.value,
      ClusterUniqueId: selectedCluster.value,
      managerId: selectedWarehouse.managerId || null,
      stateId: stateId ?? 0,
      imageFile: deliveryImage || undefined,
      eopRequests: eoPs
        .filter(eop => eop.quantityReceived > 0)
        .map(({ id, quantityReceived }) => ({
          eopId: Number(id),
          quantity: Number(quantityReceived)
        })),
    
    };

    console.log("Payload being sent", inputDistributionRequestBody);

    try {
      const response = await configService.inputDistribution(inputDistributionRequestBody);
      alert.success(response.data.message);
      refreshData();
      toggle();
    } catch (error: any) {
      if (error.response?.data?.errors) {
        const errorMessages = Object.entries(error.response.data.errors)
          .map(([field, messages]) => {
            if (Array.isArray(messages)) {
              return messages.map((msg) => `${field}: ${msg}`);
            }
            return [`${field}: ${messages}`];
          })
          .flat();

        errorMessages.forEach((message: string) => {
          alert.error(message);
        });
      } else {
        const errorMessage =
          error.response?.data?.message || "An error occurred";
        alert.error(errorMessage);
      }
    }
  };

  return (
    <Modal isOpen={openModal} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Input Distribution</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col md={6}>
              <FormGroup>
                <Label for="warehouse">Warehouse</Label>
                <Select
                  options={warehouses}
                  isSearchable
                  placeholder="Select a Warehouse"
                  value={selectedWarehouse}
                  onChange={handleWarehouseChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="cluster">Cluster</Label>
                <Select
                  options={clusters}
                  // isClearable
                  isSearchable
                  placeholder="Select a Cluster"
                  value={selectedCluster}
                  onChange={setSelectedCluster}
                />
              </FormGroup>
            </Col>
          </Row>

          {eoPs.map((eoProduct, index) => (
            <Row key={index} className="mb-2 align-items-center">
              <Col md={5}>
                <Input type="text" value={eoProduct.name} readOnly />
              </Col>
              <Col md={5}>
                <Input
                  type="number"
                  value={eoProduct.quantityReceived}
                  readOnly
                />
                <span>Remaining Quantity: {eoProduct.remainingQuantity}</span>
              </Col>
              <Col md={2} className="text-center">
                <Button
                  color="link"
                  onClick={() => removeEOProduct(index)}
                  className="p-0"
                >
                  <FaTimesCircle size={24} color="black" />
                </Button>
              </Col>
            </Row>
          ))}

          <Row className="mb-3">
            <Col md={5}>
              <Input
                type="select"
                value={currentEOProduct.id || ""}
                onChange={handleProductChange}
              >
                <option value="">Select Product</option>
                {warehouseInventories.map((item) => (
                  <option key={item.eop.eopId} value={item.eop.eopId}>
                    {item.eop.eopName} (Available:{" "}
                    {item.totalQuantity - item.distributedQuantity})
                  </option>
                ))}
              </Input>
            </Col>
            <Col md={5}>
              <Input
                type="number"
                value={currentEOProduct.quantityReceived || ""}
                onChange={handleQuantityChange}
                max={currentEOProduct.maxQuantity}
                min="0"
              />
              <span>
                Remaining Quantity: {currentEOProduct.remainingQuantity}
              </span>
            </Col>
            <Col md={2}>
              <Button color="success" onClick={addEOProduct} block>
                Add
              </Button>
            </Col>
          </Row>

          <FormGroup className="mt-4">
            <Label for="deliveryImage">Upload Delivery Image</Label>
            <Input
              type="file"
              id="imageFile"
              name="deliveryImage"
              accept="image/*"
              onChange={handleImageUpload}
              style={{
                width: "100%",
                padding: "0.375rem 0.75rem",
                border: "1px solid #ced4da",
                borderRadius: "0.25rem",
                lineHeight: "1.5",
                backgroundColor: "#fff",
              }}
            />
          </FormGroup>

          <Button color="success" type="submit" block className="mt-3">
            Save
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default InputDistribution;
