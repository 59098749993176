import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Table,
} from "reactstrap";
import { FaBoxOpen, FaWarehouse } from "react-icons/fa"; 
import { useLoadingMessage } from "../../../../../components/useLoadingMessage";
import { configService } from '../../../../../Services';
import { useAlert } from 'react-alert';
import { WarehouseInventoryItem } from "../../../../../models/config.model";

interface WarehouseInventoryProps {
  openModal: boolean;
  toggle: () => void;
  warehouseUniqueId: string;
  warehouseName?: string;
}

const ViewInventories: React.FC<WarehouseInventoryProps> = ({ warehouseUniqueId, toggle, openModal, warehouseName }) => {
  const alert = useAlert();
  ;
  const [warehouseInventories, setWarehouseInventories] = useState<WarehouseInventoryItem[]>([]);
  const { isLoading, message, updateLoader } = useLoadingMessage();

  const fetchWarehouseInventories = async (warehouseUniqueId: string) => {
  updateLoader(true, "Fetching Inventories");
  try {
    const response = await configService.getInventoriesByWarehouse(warehouseUniqueId);
    console.log("Inventory", response)
   

    if (response && response.data) {
        console.log("warehouseInventories", warehouseInventories)
      setWarehouseInventories(response.data.data || []);
    
          } else {
      setWarehouseInventories([]);
      
    }
  } catch (error) {
   
    alert.error("Failed to fetch warehouse inventories");
    setWarehouseInventories([]);
  } finally {
    updateLoader(false, "");
  }
};

  useEffect(() => {
    if (openModal) fetchWarehouseInventories(warehouseUniqueId);
     
  }, [openModal, warehouseUniqueId]);

  return (
    <Modal isOpen={openModal} toggle={toggle} className="inventory-modal">
      <ModalHeader toggle={toggle}>
        <FaWarehouse className="me-2" style={{ color: 'green' }}/> {warehouseName} Warehouse Inventory
      </ModalHeader>
      <ModalBody className="p-0">
        <div className="bg-light p-3 rounded-lg shadow-sm">
          {isLoading ? (
            <div className="text-center py-5">
              <FaBoxOpen className="text-secondary" size={48} />
              <p>{message}</p>
            </div>
          ) : (
            <div className="table-responsive">
              <Table className="table-striped table-hover text-center">
                <thead className="green">
                  <tr>
                    
                    <th>Items</th>
                    <th> Received QTY</th>
                    <th>Distributed QTY</th>
                    <th>Last Updated</th>
                  </tr>
                </thead>
                <tbody>
                  {warehouseInventories.length > 0 ? (
                    warehouseInventories.map((item) => (
                      <tr key={item.id} className="align-middle">
                
                        <td>
                          <strong>{item.eop?.eopName}</strong><br />
                         
                        </td>
                        <td>
                          <span className="text-dark">{item.totalQuantity}</span>
                        </td>
                        <td>
                          <span className="text-muted">{item.distributedQuantity}</span>
                        </td>
                        <td>
                          <small>{new Date(item.updatedAt).toLocaleDateString()}</small>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4} className="text-center">
                        <FaBoxOpen className="text-muted" size={48} /><br />
                        <strong>No Inventory Items Found</strong><br />
                        <small>No inventory items are available for this warehouse.</small>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </ModalBody>
      <div className="text-end p-3">
        <Button className="btn-create px-4 py-2 shadow bg-or ml-4" onClick={toggle}>Close</Button>
      </div>
    </Modal>
  );
};

export default ViewInventories;
