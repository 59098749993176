import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Table,
} from "reactstrap";

interface EOP {
  eopName: string;
  deliveredQuantity: number;
  quantityRequested: number;
}

interface PurchaseOrderResponseModel {
  supplierName: string;
  purchaseOrderEOPs: EOP[];
}

interface DeliveryData {
  id: number;
  acceptedDateTime: string;
  purchaseOrderResponseModel: PurchaseOrderResponseModel;
}

interface ViewDeliveryProps {
  isOpen: boolean;
  toggle: () => void;
  deliveryData: DeliveryData | null;
}

const ViewDelivery: React.FC<ViewDeliveryProps> = ({ 
  isOpen, 
  toggle, 
  deliveryData 
}) => {
  if (!deliveryData) return null;

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        Delivery Details
      </ModalHeader>
      <ModalBody>
        <div className="mb-4">
          <h6 className="font-weight-bold mb-3">General Information</h6>
          <Table bordered>
            <tbody>
              <tr>
                <th style={{ width: '30%' }}>Delivery Date</th>
                <td>
                  {new Date(deliveryData.acceptedDateTime).toLocaleDateString()}
                </td>
              </tr>
              <tr>
                <th>Supplier</th>
                <td>{deliveryData.purchaseOrderResponseModel?.supplierName}</td>
              </tr>
            </tbody>
          </Table>
        </div>

        <div>
          <h6 className="font-weight-bold mb-3">Delivered Items</h6>
          <Table bordered striped>
            <thead>
              <tr>
                <th>#</th>
                <th>Item Name</th>
                 <th>Requested Qty</th>
                <th>Delivered Quantity</th>
              </tr>
            </thead>
            <tbody>
              {deliveryData.purchaseOrderResponseModel.purchaseOrderEOPs.map((eop, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{eop.eopName}</td>
                  <td>{eop.quantityRequested}</td>
                  <td>{eop.deliveredQuantity}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <div className="text-right mt-4">
          <Button className="btn-create px-4 py-2 shadow bg-or ml-4" onClick={toggle}>
            Close
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ViewDelivery;