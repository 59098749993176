import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import AppBreadcrumb from "../../../components/layout/breadcrumb/Breadcrumb";
import { TiWeatherPartlySunny } from "react-icons/ti";
import { useLoadingMessage } from "../../../components/useLoadingMessage";
import { PageBody } from "../../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store/rootReducer";
import { useAlert } from "react-alert";
import { seasonThunk } from "../../../redux/action/season/season.thunk";
import { ReasponseSeason } from "../../../models/season.model";
import moment from "moment";
import { HiDotsVertical } from "react-icons/hi";
import { ConfirmTemplate } from "../../../components/confirm";
import { seasonService } from "../../../Services";
import Layout from "../../../components/Layout";
import { AuthContext } from "../../../context/authContext";
import { roless } from "../../../Constants";
import { saveAs } from "file-saver";

const All_Seasons = () => {
  const { role } = useContext(AuthContext);
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const dispatch = useDispatch();
  const alert = useAlert();

  const { seasons }: any = useSelector((state: RootState) => state.seasons);
  const loading = React.useCallback(
    async (page) => {
      updateLoader(true, "Fetching Data");
      await dispatch(seasonThunk.getAllSeasonsQuery(`?PageNumber=${page}`));
      updateLoader(false, "");
    },
    [updateLoader, seasons]
  );

  const getseasons = React.useCallback(async () => {
    updateLoader(true, "Fetching Data");
    await dispatch(seasonThunk.getAllSeasons());
    updateLoader(false, "");
  }, [seasons]);

  const onDisabled = (id: string, status: boolean) => {
    const request = async () => {
      updateLoader(true, "Status Changing");
      const data = {
        status: !status,
      };
      if (!status) {
        const response = await seasonService.enableSeason(id);
        if (response.status === 200) {
          alert.success("successfully Changed Status");
        } else {
          alert.error("error");
        }
      } else {
        const response = await seasonService.disableSeason(id);
        if (response.status === 200) {
          alert.success("Changed Status");
        } else {
          alert.error("error");
        }
      }
      getseasons();
      updateLoader(false, "");
    };

    ConfirmTemplate(
      {
        title: "Changing Status ",
        message: "Are You Sure You Want To Change the Status",
      },
      request
    );
  };

  const [search, setSearch] = React.useState("");

  const seasonss =
    seasons.data &&
    seasons.data.filter((farmingS: any) => {
      return (
        farmingS.seasonType.toLowerCase().indexOf(search.toLowerCase()) !==
          -1 ||
        farmingS.associationName.toLowerCase().indexOf(search.toLowerCase()) !==
          -1
      );
    });

  React.useEffect(() => {
    getseasons();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Layout active="Seasons">
        <Container>
          <Row>
            <Container className="mb-4">
              <Row>
                <Col md={9}>
                  {roless.BusinessAd == role || roless.ITAd == role ? (
                    <Link to="/create-season">
                      <Button className="btn-create px-4 py-2 shadow" inline>
                        Create Season
                      </Button>
                    </Link>
                  ) : null}

                  {roless.ITAd == role ? (
                    <Link to="/upload-template">
                      <Button
                        className=" btn-download px-4 py-2 shadow bg-or ml-4"
                        inline
                      >
                        Upload Template
                      </Button>
                    </Link>
                  ) : null}

                  {roless.BusinessAd == role || roless.ITAd == role ? (
                    <Button
                      className="btn-create px-4 py-2 shadow ml-4"
                      inline
                      onClick={async () => {
                        const response: any =
                          await seasonService.downloadCluster();
                        saveAs(response, "Template.csv");
                      }}
                    >
                      Download Template
                    </Button>
                  ) : null}

                  <span className="ml-5">
                    <Input
                      placeholder="search"
                      type="search"
                      className="form-search"
                      name="search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </span>
                </Col>
                <Col md={3} className="text-right">
                  {" "}
                  {/* <AppBreadcrumb /> */}
                </Col>
              </Row>
            </Container>
            {seasonss.length < 1 ? (
              <div className="text-center m-auto">
                <h1>No record found</h1>
              </div>
            ) : (
              seasonss.map((season: ReasponseSeason, i: number) => (
                <Col sm={6} className="mb-3 mt-4" key={i}>
                  <Card className="border-0 shadow season-card">
                    <CardBody>
                      {roless.BusinessAd == role || roless.ITAd == role ? (
                        <div className="float-right bg-gray rounded-circle shadow drop-down-circle">
                          <UncontrolledDropdown className="m-drop">
                            <DropdownToggle className="m-drop bg-transparent border-0 text-dark font-weight-bold">
                              <HiDotsVertical />
                            </DropdownToggle>
                            <DropdownMenu
                              container="body"
                              className="border-0 shadow"
                            >
                              <Link to={`/edit-season/${season.id}`}>
                                <DropdownItem>Edit</DropdownItem>
                              </Link>
                              <DropdownItem
                                onClick={() =>
                                  onDisabled(season.id, season.isActive)
                                }
                              >
                                {season.isActive ? "Deactivate" : "Activate"}
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      ) : null}
                      <div>
                        <span className="bg-green p-2 text-white ">
                          <TiWeatherPartlySunny className="dash-icon" />
                        </span>
                        <span className="font-weight-bold">
                          {" "}
                          <span className="truncate">
                            {season.seasonType + " " + season.associationName}
                          </span>
                          <br />
                          <small className="season-year">{season.year}</small>
                          {season.isActive ? (
                            <small className="bg-success p-1 text-white ml-2">
                              {" "}
                              Active{" "}
                            </small>
                          ) : (
                            <small className="bg-or p-1 text-white ml-2">
                              {" "}
                              Inactive
                            </small>
                          )}
                        </span>
                      </div>
                      <Row className="text-center mt-5">
                        <Col>
                          {" "}
                          <h6>Start</h6>{" "}
                          <p>{moment(season.startDate).format("ll")}</p>
                        </Col>
                        <Col>
                          {" "}
                          <h6>End</h6>{" "}
                          <p>{moment(season.endDate).format("ll")}</p>
                        </Col>
                      </Row>
                      <div>
                        <Link
                          // to={`/manage-season/${season.id}`}
                          to={{
                            pathname: `/manage-season/${season.id}`,
                            state: {
                              seasonData: season,
                            },
                          }}
                        >
                          <Button
                            block
                            color="success"
                            size="sm"
                            className="font-bold p-2 mt-4"
                          >
                            Manage
                          </Button>
                        </Link>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))
            )}
          </Row>
          <div className="">
            <Pagination aria-label="Page navigation">
              <PaginationItem
                disabled={
                  seasons.meta.pageNumber == seasons.meta.firstPage
                    ? true
                    : false
                }
              >
                <PaginationLink
                  first
                  onClick={() => loading(seasons.meta.pageNumber - 1)}
                />
              </PaginationItem>
              {Array.from({ length: seasons?.meta?.lastPage }).map((u, i) => (
                <PaginationItem
                  active={seasons.meta.pageNumber == i + 1 ? true : false}
                >
                  <PaginationLink onClick={() => loading(i + 1)}>
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem
                disabled={
                  seasons.meta.pageNumber == seasons.meta.lastPage
                    ? true
                    : false
                }
              >
                <PaginationLink
                  last
                  onClick={() => loading(seasons.meta.nextPage)}
                />
              </PaginationItem>
            </Pagination>
          </div>
        </Container>
      </Layout>
    </PageBody>
  );
};

export default All_Seasons;
